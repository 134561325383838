import * as CryptoJS from 'crypto-js';
import storage from "@/utils/storage.js";

/*AES加密*/
export function Encrypt(encryptstr, AuthTokenKey) {
    let AuthTokenIv = AuthTokenKey.substr(0, 16);
    let dataStr = JSON.stringify(encryptstr);
    let encrypted = CryptoJS.AES.encrypt(dataStr, CryptoJS.enc.Latin1.parse(AuthTokenKey), {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: CryptoJS.enc.Latin1.parse(AuthTokenIv)
    });
    return encrypted.toString();
}

/*AES解密*/
export function Decrypt(decryptstr, AuthTokenKey) {

    let AuthTokenIv = AuthTokenKey.substr(0, 16);
    let dataStr = decryptstr.replace(/\n/gm, "");
    let decrypted = CryptoJS.AES.decrypt(dataStr, CryptoJS.enc.Latin1.parse(AuthTokenKey), {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: CryptoJS.enc.Latin1.parse(AuthTokenIv)
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
}


/*Base64编码*/
export function EncodeBase64(data) {
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data));
}

/*Base64解码*/
export function DecodeBase64(data) {
    data = CryptoJS.enc.Base64.parse(data);
    return data.toString(CryptoJS.enc.Utf8);
}



/*调用者加密 */
export function EncryptCaller(data,callee) {
    let dataStr = Encrypt(data, callee);
    return EncodeBase64(dataStr);
}

/*调用者解密 */
export function DecryptCaller(data,callee) {
    let dataStr = DecodeBase64(data);
    return Decrypt(dataStr, callee);
}

/*用户加密 */
export function EncryptUser(data,callee) {
    //console.log(data)
    let keys = storage.getStorage('kkun_keys');
    keys = DecryptCaller(keys,callee);
    let dataStr = Encrypt(data, keys);
    return EncodeBase64(dataStr);
}

/*用户解密 */
export function DecryptUser(data,callee) {
    let keys = storage.getStorage('kkun_keys');
    keys = DecryptCaller(keys,callee);
    let dataStr = DecodeBase64(data);
    return Decrypt(dataStr, keys);
}
