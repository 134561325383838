
const modules = {};
const injection = files => {
  files.keys().forEach(key => {
    modules[key.replace(/(\.\/|\.js)/g, "")] = files(key).default;

  });
};
injection(require.context("./modules", false, /\.js$/));
export default {
  namespaced: true,
  modules
};
