/*
 * @Author: luoyan
 * @Date: 2023-09-04 15:42:54
 * @LastEditTime: 2023-10-17 10:18:18
 * @Description: file content
 */
import storage from "@/utils/storage"
import request from "@/plugin/axios"
import { ddmg_config } from "@/utils/appConfig"
import { EncodeBase64 } from "@/utils/encryption"

export const startApiReq = ({ data = {}, callmethod }, timeout = 15000) => {
  // const token = util.cookies.get("token");
  // if (callmethod != "api900103" && token && token !== "undefined") {
  //   // 用户有做操作，请求接口不为心跳，token自动延期2小时
  //   const currentTime = new Date().getTime();
  //   let expiresTime = new Date(currentTime + 1000 * 60 * 120);
  //   util.cookies.set("token", token, { expires: expiresTime });
  // }
  const headers = {
    callmethod,
    hostid: ddmg_config.host,
    caller: ddmg_config.caller,
    customdata: window.btoa(
      JSON.stringify({
        login_company_id: storage.getStorage("company_id")
          ? storage.getStorage("company_id")
          : "",
        login_user_id: storage.getStorage("user_id")
          ? storage.getStorage("user_id")
          : "",
      })
    ),
  }
  console.log("callmethod===", callmethod)
  console.log("headers===", headers)
  let url = ddmg_config.apiurl
  return request({
    url: url,
    method: "post",
    headers,
    data,
    timeout,
  })
}
