<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2021-11-16 14:18:17
 * @Description: file content
-->
<template>
  <div class="page">
    <p class="page_title">404 page not found</p>
    <button style="margin-top:30px;padding:5px" @click="JumpHome">返回首页</button>
  </div>
</template>

<script>
    export default {
        methods: {
            JumpHome() {
                this.$router.push({path: '/index'});
            },
        }
    }
</script>

<style scoped>
  .page {
    background: #303133;
    background-blend-mode: multiply, multiply;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

  }
  .page_title {
    font-size: 20px;
    color: #fff;
  }
</style>
