import Vue from "vue"
import App from "./App.vue"
import util from "@/libs/util.js"
import { getEvnConfig } from "@/utils/appConfig"
// store
import store from "@/store/index"
// 引入echarts
// import echarts from 'echarts'
// Vue.prototype.$echarts = echarts
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
Vue.use(ElementUI)
Vue.config.ignoredElements=['wx-open-launch-weapp']
// 菜单和路由设置
import router from "./router"
Vue.config.devtools = true
import VueRouter from "vue-router"
import storage from "@/utils/storage";
Vue.prototype.isToken = function () {
  //changeData是函数名
  let type = getEvnConfig()
  let hostUrl = window.location.href
  hostUrl = hostUrl.substring(hostUrl.lastIndexOf("#") + 1, hostUrl.length)
  hostUrl = hostUrl.substring(hostUrl.lastIndexOf("/") + 1, hostUrl.length)
  let loginUrl = "work.ddmg.com/#/login?front_path=" + hostUrl
  let url = ""
  if (type == 0) {
    url = "https://" + loginUrl
  } else if (type == 1) {
    url = "https://p" + loginUrl
  } else if (type == 2) {
    url = "https://t" + loginUrl
  } else if (type == 3) {
    url = "https://d" + loginUrl
  }
  //let token = util.cookies.get("mall_token");
  let data = util.cookies.get("ddmg_hanhe_logininfo")
  if (data) {
    return true
  } else {
    console.log("url", url)
    window.location.href = url
  }
}
Vue.prototype.toCustomer = function (){
  let type = getEvnConfig()
  let userId = storage.getStorage("user_id") ;
  if(!userId){
    this.$message.error("请先登录！");
    setTimeout(() => {
      this.isToken()
    }, 1000);
    return;
  }
  let url='';
  if (type == 0) {
    url = 'https://chat.dingtalk.com/h5/web/page/visitor/home?code=d2bca80e4b4141f0a21ef068a6210c7a&token='+userId
  }else {
    url = 'https://chat.dingtalk.com/h5/web/page/visitor/home?code=1c4f764b6b724db99b4c3915d34e56ce&token='+userId
  }

  window.open(url, "_target");

}
//内部跳转
Vue.prototype.JumpInside = function (type, id, state) {
  let routeUrl
  if (type == 1) {
    //商铺列表
    routeUrl = this.$router.resolve({
      path: "/shoplist",
    })
  }
  if (type == 2) {
    //产品
    routeUrl = this.$router.resolve({
      path: "/product",
      query: { id: id },
    })
  }
  if (type == 3) {
    //商铺
    routeUrl = this.$router.resolve({
      path: "/shop",
      query: { id: id },
    })
  }
  if (type == 4) {
    //询价
    routeUrl = this.$router.resolve({
      path: "/inquiry",
    })
  }
  if (type == 5) {
    //竞价
    routeUrl = this.$router.resolve({
      path: "/bid",
    })
  }
  if (type == 6) {
    //现货资源
    routeUrl = this.$router.resolve({
      path: "/mall",
    })
  }
  if (type == 7) {
    //现货资源-产品
    routeUrl = this.$router.resolve({
      path: "/product",
      query: { id: id, type: 1 },
    })
  }
  if (type == 8) {
    //竞价
    routeUrl = this.$router.resolve({
      path: "/offerDetails",
      query: { id: id, type: state },
    })
  }
  window.open(routeUrl.href, "_blank")
}
//跳转中台
Vue.prototype.JumpCenter = function (s, suffix = "") {
  let arr = [
    "registered", //注册
    "buyerCenter/index", //买家中心
    "sellerCenter/index", //卖家中心
    "buyerCenter/myOrder", //我的订单
    "buyerCenter/myOrder/selfOrder", //自助下单
    "sellerCenter/accessRequest", //权限申请
    "login", //登录
    "buyerCenter/enterpriseCertification", //登录
    "sellerCenter/centralizedOffer/centralizedOfferQuote", //集采报价
  ]
  let type = getEvnConfig() //环境
  let urlStr = "work.ddmg.com/#/" + arr[s]
  let url = ""
  if (type == 0) {
    url = "https://" + urlStr
  } else if (type == 1) {
    url = "https://p" + urlStr
  } else if (type == 2) {
    url = "https://t" + urlStr
  } else if (type == 3) {
    url = "https://d" + urlStr
  }
  window.location.href = url + suffix
}
Vue.config.productionTip = false
Vue.use(VueRouter)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
