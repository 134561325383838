// import JSEncrypt from "jsencrypt"
import JSEncrypt from "@/utils/jsencrypt.min.js"
import * as CryptoJS from "crypto-js"
import storage from "@/utils/storage"
import { appInfo, ddmg_config } from "@/utils/appConfig"
import store from "@/store/index"
import { Message } from "element-ui"
import { EncodeBase64, EncryptCaller, DecryptCaller } from "@/utils/encryption"

const public_Encrypt = (public_key, value) => {
  const jsencrypt = new JSEncrypt()
  jsencrypt.setPublicKey(public_key)
  return jsencrypt.encrypt(value)
}
//清除字符串尾部空格
const trimData = (data) => {
  if (typeof data == "string") {
    return data.trim()
  } else if (Array.isArray(data)) {
    return data.map((it) => trimData(it))
  } else if (Object.prototype.toString.call(data) == "[object Object]") {
    return Object.keys(data).reduce(
      (c, n) => ({ ...c, [n]: trimData(data[n]) }),
      {}
    )
  }
  return data
}
const MD5 = (value) => CryptoJS.MD5(value).toString()

const getEventid = () =>
  Math.floor(Math.random() * (99999999 - 10000000) + 10000000)

// 获取载荷

export const getPayload = (callmethod, data = "") => {
  // console.log(`不登录请求接口${callmethod},请求加密前参数${JSON.stringify(data)}`);
  const clientid = storage.getStorage("fingerprint")
  // 传输凭据被清空
  if (!clientid) {
    Message({
      type: "error",
      showClose: true,
      message: "本地指纹凭据信息已被清空",
      onClose: () => {
        window.location.reload()
      },
    })
  }
  try {
    const timestamp = new Date().getTime()
    const reqBody = {
      timestamp,
      callmethod,
      version: 1,
      isdebug: 1,
      clientid,
      data: trimData(data),
    }
    var reqJson = {
      ...appInfo,
      _host: ddmg_config.host,
      _caller: ddmg_config.caller,
      _callmethod: callmethod,
      _timestamp: timestamp,
      _eventid: getEventid(),
      _data: EncryptCaller(reqBody, ddmg_config.callee),
    }
    // console.log('getPayload', reqJson, reqBody);
  } catch (error) {
    console.log("getPayload-catch =>", error)
  } finally {
    return {
      callmethod,
      data: reqJson,
    }
  }
}

// 获取登陆载荷
export const getLoginPayload = (callmethod, data = "") => {
  // 若有传menuId 则以传入的menuId为准；没有传menuId则统一处理；

  // console.log(`请求接口${callmethod},请求加密前参数${JSON.stringify(data)}`)
  const kkun_uid = storage.getStorage("kkun_uid")
  console.log("kkun_uid==", kkun_uid)
  const clientid = storage.getStorage("fingerprint")
  console.log("clientid==", clientid)
  console.log("rsa_pub==", storage.getStorage("rsa_pub"))
  const rsa_pub = DecryptCaller(
    storage.getStorage("rsa_pub") || "",
    ddmg_config.callee
  )
  // 传输凭据被清空
  if (!kkun_uid || !clientid || !rsa_pub) {
    store.dispatch("d2admin/account/logout")
    // Message({
    //  type: "error",
    //  showClose: true,
    //  message: "本地登录凭据信息已被清空，请重新登录",
    // onClose: () => {
    // console.log('kkun_uid==',kkun_uid)
    // console.log('clientid==',clientid)
    // console.log('1111==',rsa_pub)
    //  store.dispatch("d2admin/account/logout");
    // }
    //  });
    return
  }
  try {
    const timestamp = new Date().getTime()
    const uid = DecryptCaller(kkun_uid || "", ddmg_config.callee)
    const params = {
      _caller: ddmg_config.caller,
      _callmethod: callmethod,
      _eventid: getEventid(),
      _timestamp: timestamp,
      _host: ddmg_config.host,
    }
    const reqBody = {
      timestamp,
      callmethod,
      version: 1,
      isdebug: 1,
      clientid,
      data: trimData(data),
      uid,
    }
    const loginkey = {
      uid,
      ...params,
    }
    const encryption_data = EncryptCaller(reqBody, ddmg_config.callee)
    const join_loginkey = Object.values(loginkey).join(",")
    const md5_data = MD5(encryption_data)
    const md5_loginkey = MD5(join_loginkey)
    const join_md5 = md5_data + "," + md5_loginkey
    const rsa_data = public_Encrypt(rsa_pub, join_md5)
    const base64_data = EncodeBase64(rsa_data)
    var reqJson = {
      ...params,
      ...appInfo,
      _loginkey: base64_data,
      _data: encryption_data,
    }
    if (!rsa_data || base64_data.length < 15) {
      Message({
        type: "error",
        showClose: true,
        message: "_loginkey加密不正确",
      })
    }
    // console.log('getLoginPayload', reqJson, reqBody);
  } catch (error) {
    Message({
      type: "error",
      showClose: true,
      message: "传输信息加密错误，请重新登录更新凭证信息",
      onClose: () => {
        store.dispatch("d2admin/account/logout")
      },
    })
    console.log("getLoginPayload-catch =>", error)
  } finally {
    return {
      callmethod,
      data: reqJson,
    }
  }
}
