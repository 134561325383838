import store from "@/store";
import axios from "axios";
//import util from "@/libs/util.js";
import { Message } from "element-ui";

const timeout = 5 * 60 * 1000; // 请求延迟
const errorDuration = 2 * 1000; // 错误显示延迟

// 创建一个 axios 实例
const service = axios.create({
  timeout // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  config => config,
  error => {
    // 发送失败
    console.log('asdasdsad',error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  result => {
    const res = result.data;
    const msgcode = String(res.msgcode);
    // 异常处理
    if (res.msgcode != "1") {
      if(res.msgcode == "-3" ){
          store.dispatch("d2admin/account/logout");
      }
      Message({
        type: "error",
        message: res.message,
        duration: errorDuration
      });
    }

    return Promise.resolve(res);
  },
  error => {
    Message({
      type: "error",
      message: error.message || "error",
      duration: errorDuration
    });
    console.log('asdasdsad',error);
    return Promise.reject(error);
  }
);

export default service;
