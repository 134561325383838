import * as HttpServices from "@/utils/http";
import { getLoginPayload,getPayload } from "@/utils/server";
import dayjs from 'dayjs'
export default {
  namespaced: true,
  moduleName: "third", // 模块名
  state: {
    userInfo:null,
    guidanceLoading:false,
    a: 34,
    dayLoading:false,
    cityLoading:false,
    companyLoading:false,
    orderTrendChartPcData: {},
    transactionOrderList: [],
    homeMallList: [],
    bidActivityWinnerList: [],
    engineeringList: [],
    cityName: '长沙市',
    cityList: [],
    companyList: [],
    regionList: [],
    PMList: [],
    priceLoading:[],
    cityPriceTrendsNewList: {
      "code": 0,
      "message": 0,
      meta: {
        date: ''
      },
      "data": [
        {
          "update_time": "",
          "product_name": "",
          "material": "",
          "specifications": "",
          "origin_place": "",
          "price": "",
          "gc_data": [
            {
              "origin_place": "",
              "diff_price": "",
              "status": "",
              "price": ""
            }
          ],
          "seven_day_data": [
            {
              "date": "",
              "diff_price": "",
              "status": "",
              "price": ""
            }
          ],
          "city_data": [
            {
              "city_name": "",
              "diff_price": "",
              "status": "",
              "price": ""
            }
          ]
        }
      ]
    }
  },
  mutations: {
    setUserInfo: (state, data) => {
      state.userInfo = data;
    },
    setHomeMallDataIndex: (state, data) => {
      state.homeMallList = data;
      if (state.homeMallList.length > 6) {
        state.homeMallList.length = 6;

      }
      console.log('首页-现货：',state.homeMallList)
    },
    setOrderTrendChartPc: (state, data) => {
      state.orderTrendChartPcData = data;
    },
    setTransactionOrder: (state, data) => {
      data.map(obj=>{
        obj.sale = obj.sale.toFixed(2);
        obj.rq_ =  dayjs( obj.rq_).format("MM-DD HH:mm");
      })
      state.transactionOrderList = data;
    },
    setBidActivityWinne: (state, data) => {
      if (data.length > 5) {
        data.length = 5
      }
      data.map(obj=>{ obj.appraiseTime =  dayjs( obj.appraiseTime).format("YYYY-MM-DD");})
      state.bidActivityWinnerList = data;
    },
    setEngineeringList: (state, data) => {
      state.engineeringList = data;
    },
    setAreaCity: (state, data) => {
      state.cityList = data;
      state.cityName = state.cityList[0];
    },
    setCity: (state, data) => {
      state.cityName = data;
    },
    setCompany: (state, data) => {
      if (data.length > 3) {
        data.length = 3
      }
      data.forEach(item => {
        if(item.goods){
          if (item.goods.length > 2) {
            item.goods.length = 2;
          }
          item.goods.map(obj=>{obj.createTime = dayjs(obj.createTime).format("MM-DD HH:mm")})
        }
      });
      state.companyList = data;
    },
    setRegion: (state, data) => {
      state.regionList = data;
    },
    setPM: (state, data) => {
      state.PMList = data;
    },
    setCityPriceTrendsNewList: (state, data) => {
      state.cityPriceTrendsNewList = data;
    },
  },
  actions: {
    //价格指导-列表
    async priceTrendsList({ state, commit }, params) {
      try {
        state.pageLoading = true;
        const payload = getPayload("hhd207002",params);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          return res
        }
      } finally {
        state.pageLoading = false;
      }
    },
    //退出登录
    async getQuitLogin({ state, commit }, params) {
      try {
        state.pageLoading = true;
        let body = { token:params };
        const payload = getLoginPayload("hhm978191",body);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          return res
        }
      } finally {
        state.pageLoading = false;
      }
    },
    //商铺推荐-区域
    async getRegionAndProduct({ state, commit }, params) {
      try {
        state.pageLoading = true;
        let body = { };
        const payload = getPayload("hhm997112",body);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          commit("setRegion", res.resultdata.areaList);
          return res
        }
      } finally {
        state.pageLoading = false;
      }
    },
    //轮播图
    async getBroadcastMap({ state, commit }, params) {
      try {
        state.pageLoading = true;
        let body = { type:'pc' };
        const payload = getPayload("hhm997013",body);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          return res
        }
      } finally {
        state.pageLoading = false;
      }
    },
    //商铺推荐
    async getCompany({ state, commit }, params) {
      try {
        state.pageLoading = true;
        let body = { rows:3,area:params,isGetGoods:1 };
        const payload = getPayload("hhm997012",body);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          commit("setCompany", res.resultdata.data);
        }
      } finally {
        state.pageLoading = false;
      }
    },
    //头部城市
    async getAreaCity({ state, commit }, params) {
      try {
        state.pageLoading = true;
        let body = {
          clientIp:params
        }
        const payload = getPayload("hhm976113",body);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          commit("setAreaCity", res.resultdata);

        }
      } finally {
        state.pageLoading = false;
      }
    },
    //工程专场
    async getEngineeringList({ state, commit }, params) {
      try {
        state.pageLoading = true;
        let body = {rows:3, };
        const payload = getPayload("hhm970017",body);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          commit("setEngineeringList", res.resultdata.data);
        }
      } finally {
        state.pageLoading = false;
      }
    },
    //中标公示
    async getBidActivityWinner({ state, commit }, params) {
      try {
        state.pageLoading = true;
        let body = { status:1 };
        const payload = getPayload("hhm970016",body);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          commit("setBidActivityWinne", res.resultdata);
        }
      } finally {
        state.pageLoading = false;
      }
    },
    //现货资源-初始化
    async getHomeMallData({ state, commit }, params) {
      try {
        state.pageLoading = true;
        let body = {};
        const payload = getPayload("hhm976112",body);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          return  res
        }
      } finally {
        state.pageLoading = false;
      }
    },
    //现货资源
    async getHomeMallDataIndex({ state, commit }, params) {
      try {
        state.pageLoading = true;
        let body = {
          productName:params
        };
        const payload = getPayload("hhm976012",body);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          commit("setHomeMallDataIndex", res.resultdata);
        }
      } finally {
        state.pageLoading = false;
      }
    },

    async getDay({ state, commit }, params = {}) {
      try {
        state.dayLoading = true;
        const payload = getPayload("hhd207001", params);
        const res = await HttpServices.startApiReq(payload, 1000 * 60 * 5);
        if (res.msgcode === 1) {
          return  res.resultdata.cityPriceList
        }
      } finally {
        state.dayLoading = false;
      }
    },
    async getCity({ state, commit }, params = {}) {
      try {
        state.cityLoading = true;
        const payload = getPayload("hhd207001", params);
        const res = await HttpServices.startApiReq(payload, 1000 * 60 * 5);
        if (res.msgcode === 1) {
          return res.resultdata.cityPriceList
        }
      } finally {
        state.cityLoading = false;
      }
    },
    async priceTrendsMarkUpList({ state, commit }, params = {}) {
      try {
        state.priceLoading = true;
        const payload = getPayload("hhd207004", params);
        const res = await HttpServices.startApiReq(payload, 1000 * 60 * 5);
        if (res.msgcode === 1) {
          state.priceLoading = false;
          return res.resultdata
        }
      } finally {
        state.priceLoading = false;
      }
    },
    //实时数据
    async getRealTimeData({ state, commit }, params = {}) {
      try {
        state.pageLoading = true;
        const payload = getPayload("hhd310001", params);
        const res = await HttpServices.startApiReq(payload, 1000 * 60 * 5);
        if (res.msgcode === 1) {
          return res
        }
      } finally {
        state.pageLoading = false;
      }
    },
    //成交走势
    async getTransactionData({ state, commit }, params = {}) {
      try {
        state.pageLoading = true;
        const payload = getPayload("hhd310002", params);
        const res = await HttpServices.startApiReq(payload, 1000 * 60 * 5);
        if (res.msgcode === 1) {
          return res
        }
      } finally {
        state.pageLoading = false;
      }
    },
    //成交订单
    async getTransactionOrder({ state, commit }, params = {}) {
      try {
        state.pageLoading = true;
        const payload = getPayload("rpt000912", params);
        const res = await HttpServices.startApiReq(payload, 1000 * 60 * 5);
        if (res.msgcode === 1) {
          commit("setTransactionOrder", res.resultdata.list);
        }
      } finally {
        state.pageLoading = false;
      }
    },
  }
};
