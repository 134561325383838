import * as HttpServices from "@/utils/http";
import { getLoginPayload,getPayload } from "@/utils/server";
import dayjs from 'dayjs'
export default {
  namespaced: true,
  moduleName: "cart", // 模块名
  state: {


  },
  mutations: {
    setCompany: (state, data) => {
      state.companyList = data.data;
      state.pagination = data;
    },

  },
  actions: {
    //省市区
    async getArea({ state, commit }, params) {
      try {
        state.pageLoading = true;
        let body = {};
        const payload = getLoginPayload("hhc101011",body);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          return res
        }
      } finally {
        state.pageLoading = false;
      }
    },
    //初始化
    async getCartInfo({ state, commit }, params) {
      try {
        state.pageLoading = true;
        const payload = getLoginPayload("hhm971022",params);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          return res.resultdata
        }
      } finally {
        state.pageLoading = false;
      }
    },
    //确定
    async getCartSubmit({ state }, params) {
      try {
        state.pageLoading2 = true;
        const payload = getLoginPayload("hhm971021",params);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          return res;
        }
      } finally {
        state.pageLoading2 = false;
      }
    },

  }
};
