/*
 * @Author: luoyan
 * @Date: 2021-08-17 14:55:54
 * @LastEditTime: 2023-10-19 18:29:06
 * @Description: file content
 */
const evnConfig = {
  isMock: false,
  // environment: 0, //0：表示正式环境，1：表示演示环境，2：表示测试环境，3：开发环境1
  environment: window.ddmg_config_index,
}
const getEvnConfig = () => {
  return evnConfig.environment
}
const appInfo = {
  _appname: "ddmg_hanhe",
  _appentrance: "ddmg_hanhe_pc",
  _appversion: "v1.0",
}

const CallInfo = () => {
  const calls = [
    // 正式环境
    {
      apiurl: "https://hhbgtw.ddmg.com/api/BaseCall",
      host: "8006",
      caller: "IaulyuHZyWlx",
      callee: "U7yPgY6BR3PUwAiP8gL6jR9Kd2WPrDHW",
    },
    // 演示环境
    {
      apiurl: "https://phhbgtw.ddmg.com/api/basecall",
      host: "8006",
      caller: "vzX7Cy24Ybmj",
      callee: "k3QnKJPpflS2vs4pH9T86K39MWL9S9W5",
    },
    // 测试环境
    {
      apiurl: "https://hhgtw.ddmg.com/api/basecall",
      host: "8006",
      caller: "k8GXKZ0ZQlRE",
      callee: "GixKwvagDVbeZ1ZWI0JhJfPjcIdK8PCV",
    },
    // 开发环境
    {
      apiurl: "https://dhhgtw.ddmg.com/api/basecall",
      host: "8005",
      caller: "S1kbNpTncVqV",
      callee: "RZnbveVyknj5VsLsYBgrctkJXA1cDWVA",
    },
  ]
  return calls[window.ddmg_config_index]
}

const ddmg_config = { ...CallInfo() }

export { appInfo, ddmg_config, evnConfig, getEvnConfig }
