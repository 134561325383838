<template>
  <div id="app">
    <router-view  style="min-width: 1200px"/>
  </div>
</template>

<script>
//import index from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
  //  HelloWorld
  }
}
</script>
<style lang="scss">

* {
 margin: 0;
 padding: 0;
 text-decoration: none;
  font-family: PingFang SC;
 list-style: none;
}
</style>


