import * as HttpServices from "@/utils/http";
import { getLoginPayload,getPayload } from "@/utils/server";
import dayjs from "dayjs";
export default {
  namespaced: true,
  moduleName: "bid", // 模块名
  //tableColumns,
  //  表头列表

  state: {
    // 额外的state
    pageLoading: false,
    inquiryList: [],
    pagination: {},
    pagination2: {},
    interval: null,
    interval2: null,
    interval3: null,
    interval4: null,
    dataInfo: {},
    timeStr: '',
    timeStr2: {
      day: "",
      hour: "",
      minute: "",
      second: "",
    },
    PMlist: [],
    bidActivityWinnerList: [],
    inquiryPriceList: [],
    offerDetailsInfo: {},
    offerDetails: [],

    inquiryDetailsInfo: {},
    inquiryDetails: [],
  },
  mutations: {
    setPagination(state, payload) {
      state.pagination = payload
    },
    setOfferDetailsInfo(state, payload) {
      state.PMlist = payload.head.productNames
      payload.details.forEach(item => {
        item.offerOriginPlace = item.originPlace;
        if(! item.offerPrice){ item.offerPrice = 0;}
        if(! item.offerAmount){ item.offerAmount = 0;}
        // item.activity_detail_id =item.id
      })
      payload.head.winnerCompanyName = payload.head.winnerCompanyName.substring(0,3) +'***' + payload.head.winnerCompanyName.substring(payload.head.winnerCompanyName.length - 3, payload.head.winnerCompanyName.length)
      state.offerDetailsInfo = payload.head;
      state.offerDetails = payload.details;

    },
    setInquiryDetailsInfo(state, payload) {
      payload.head.sellerUnitName =payload.head.sellerUnitName? payload.head.sellerUnitName.substring(0,3) +'***' + payload.head.sellerUnitName.substring(payload.head.sellerUnitName.length - 3, payload.head.sellerUnitName.length):''
      state.inquiryDetailsInfo = payload.head;
      state.inquiryDetails = payload.details;
      console.log(state,"state")
    },
    setPagination2(state, payload) {
      state.pagination2 = payload
    },


    setDataInfo(state, payload) {
      console.log(payload)
      payload.weightAll = 0
      payload.items.forEach(oitem => {
        payload.weightAll = oitem.weight * 1 + payload.weightAll
        oitem.activity_detail_id = oitem.id
        oitem.pricing_method = ""
        oitem.available_weight = oitem.weight
      })
      if (payload.items.length > 2) {
        payload.detailStr = payload.items[0].product_name + '、' + payload.items[1].product_name + '等' + payload.items.length + '条物资'
      } else if (payload.items.length == 2) {
        payload.detailStr = payload.items[0].product_name + '、' + payload.items[1].product_name
      } else if (payload.items.length == 1) {
        payload.detailStr = payload.items[0].product_name
      }
      state.dataInfo = payload
    },
    setBidActivityWinne: (state, data) => {
      state.bidActivityWinnerList = data;
    },
    setInquiryPriceList: (state, payload) => {
      if (state.interval3 != null) {
        clearInterval(state.interval3)
        state.interval3 = setInterval(function () { //倒计时
          payload.forEach(item => {
            var now = new Date();
            var leftTime = ''
            if (item.activity_status == 1) {
              leftTime = item.activate_time_start * 1000 - now.getTime();
            } else {
              leftTime = item.activate_delayed_end * 1000 - now.getTime();

            }
            if (leftTime > 0) {
              let leftsecond = parseInt(leftTime / 1000);
              var day1 = Math.floor(leftsecond / (60 * 60 * 24));
              var hour = Math.floor((leftsecond - day1 * 24 * 60 * 60) / 3600);
              var minute = Math.floor((leftsecond - day1 * 24 * 60 * 60 - hour * 3600) / 60);
              var second = Math.floor(leftsecond - day1 * 24 * 60 * 60 - hour * 3600 - minute * 60);
              if (day1 < 10) {
                day1 = "0" + day1;
              }
              if (hour < 10) {
                hour = "0" + hour;
              }
              if (minute < 10) {
                minute = "0" + minute;
              }
              if (second < 10) {
                second = "0" + second;
              }
              item.timeStr = day1 + '天' + hour + '时' +
                  minute + '分' + second + '秒'
            }
          })
          state.inquiryPriceList = payload;
        }, 1000);
      } else {
        state.interval3 = setInterval(function () { //倒计时
          payload.forEach(item => {
            var now = new Date();
            var leftTime = ''
            if (item.activity_status == 1) {
              leftTime = item.activate_time_start * 1000 - now.getTime();
            } else {
              leftTime = item.activate_delayed_end * 1000 - now.getTime();
            }
            if (leftTime > 0) {
              let leftsecond = parseInt(leftTime / 1000);
              var day1 = Math.floor(leftsecond / (60 * 60 * 24));
              var hour = Math.floor((leftsecond - day1 * 24 * 60 * 60) / 3600);
              var minute = Math.floor((leftsecond - day1 * 24 * 60 * 60 - hour * 3600) / 60);
              var second = Math.floor(leftsecond - day1 * 24 * 60 * 60 - hour * 3600 - minute * 60);
              if (day1 < 10) {
                day1 = "0" + day1;
              }
              if (hour < 10) {
                hour = "0" + hour;
              }
              if (minute < 10) {
                minute = "0" + minute;
              }
              if (second < 10) {
                second = "0" + second;
              }
              item.timeStr = day1 + '天' + hour + '时' +
                  minute + '分' + second + '秒'
            }
          })
          state.inquiryPriceList = payload;
        }, 1000);
      }
    },
    setTimeStr2(state, payload) {
      if (state.interval2 != null) {
        clearInterval(state.interval2);

        state.interval2 = setInterval(function () {
          var now = new Date();
          var leftTime =  payload.activateDelayedEndSec * 1000 - now.getTime();
          if (leftTime > 0) {
            let leftsecond = parseInt(leftTime / 1000);
            var day1 = Math.floor(leftsecond / (60 * 60 * 24));
            var hour = Math.floor((leftsecond - day1 * 24 * 60 * 60) / 3600);
            var minute = Math.floor(
                (leftsecond - day1 * 24 * 60 * 60 - hour * 3600) / 60
            );
            var second = Math.floor(
                leftsecond - day1 * 24 * 60 * 60 - hour * 3600 - minute * 60
            );
            if (day1 < 10) {
              day1 = "0" + day1;
            }
            if (hour < 10) {
              hour = "0" + hour;
            }
            if (minute < 10) {
              minute = "0" + minute;
            }
            if (second < 10) {
              second = "0" + second;
            }
            payload.timeDHMS = {
              day: day1,
              hour: hour,
              minute: minute,
              second: second,
            }
          }
          state.timeStr2 = payload.timeDHMS
        }, 1000);
      } else {
        state.interval2 = setInterval(function () {
          //倒计时
          var now = new Date();
          var leftTime =  payload.activateDelayedEndSec * 1000 - now.getTime();
          if (leftTime > 0) {
            let leftsecond = parseInt(leftTime / 1000);
            var day1 = Math.floor(leftsecond / (60 * 60 * 24));
            var hour = Math.floor((leftsecond - day1 * 24 * 60 * 60) / 3600);
            var minute = Math.floor(
                (leftsecond - day1 * 24 * 60 * 60 - hour * 3600) / 60
            );
            var second = Math.floor(
                leftsecond - day1 * 24 * 60 * 60 - hour * 3600 - minute * 60
            );
            if (day1 < 10) {
              day1 = "0" + day1;
            }
            if (hour < 10) {
              hour = "0" + hour;
            }
            if (minute < 10) {
              minute = "0" + minute;
            }
            if (second < 10) {
              second = "0" + second;
            }
            payload.timeDHMS = {
              day: day1,
              hour: hour,
              minute: minute,
              second: second,
            }
          }
          state.timeStr2 = payload.timeDHMS
        }, 1000);
      }
    },
    setTimeStr(state, payload) {
      console.log("payload.timeStr1")
      if (state.interval2 != null) {
        clearInterval(state.interval2);
        state.interval2 = setInterval(function () {
          //倒计时
          var now = new Date();
          var leftTime =  dayjs(payload.quoteEndTime).valueOf() - now.getTime();
          if (leftTime > 0) {
            let leftsecond = parseInt(leftTime / 1000);
            var day1 = Math.floor(leftsecond / (60 * 60 * 24));
            var hour = Math.floor((leftsecond - day1 * 24 * 60 * 60) / 3600);
            var minute = Math.floor(
                (leftsecond - day1 * 24 * 60 * 60 - hour * 3600) / 60
            );
            var second = Math.floor(
                leftsecond - day1 * 24 * 60 * 60 - hour * 3600 - minute * 60
            );
            if (day1 < 10) {
              day1 = "0" + day1;
            }
            if (hour < 10) {
              hour = "0" + hour;
            }
            if (minute < 10) {
              minute = "0" + minute;
            }
            if (second < 10) {
              second = "0" + second;
            }
            payload.timeStr = {
              day: day1,
              hour: hour,
              minute: minute,
              second: second,
            }
          }

          state.timeStr = payload.timeStr
        }, 1000);
      } else {

        state.interval2 = setInterval(function () {
          //倒计时
          var now = new Date();
          var leftTime =  dayjs(payload.quoteEndTime).valueOf() - now.getTime();

          if (leftTime > 0) {
            let leftsecond = parseInt(leftTime / 1000);
            var day1 = Math.floor(leftsecond / (60 * 60 * 24));
            var hour = Math.floor((leftsecond - day1 * 24 * 60 * 60) / 3600);
            var minute = Math.floor(
                (leftsecond - day1 * 24 * 60 * 60 - hour * 3600) / 60
            );
            var second = Math.floor(
                leftsecond - day1 * 24 * 60 * 60 - hour * 3600 - minute * 60
            );
            if (day1 < 10) {
              day1 = "0" + day1;
            }
            if (hour < 10) {
              hour = "0" + hour;
            }
            if (minute < 10) {
              minute = "0" + minute;
            }
            if (second < 10) {
              second = "0" + second;
            }
            payload.timeStr = {
              day: day1,
              hour: hour,
              minute: minute,
              second: second,
            }
          }
          state.timeStr = payload.timeStr
        }, 1000);
      }
    },
    setInquiryList(state, payload) {
      state.inquiryList = payload;
      /*if (state.interval != null) {
        clearInterval(state.interval)
        state.interval = setInterval(function () { //倒计时
          payload.forEach(item => {
            var now = new Date();
            var leftTime = 0
            if (item.orderStatus.value==2) {
              leftTime =  dayjs(item.quoteEndTime).valueOf() - now.getTime();
            }
            if (leftTime > 0) {
              let leftsecond = parseInt(leftTime / 1000);
              var day1 = Math.floor(leftsecond / (60 * 60 * 24));
              var hour = Math.floor((leftsecond - day1 * 24 * 60 * 60) / 3600);
              var minute = Math.floor((leftsecond - day1 * 24 * 60 * 60 - hour * 3600) / 60);
              var second = Math.floor(leftsecond - day1 * 24 * 60 * 60 - hour * 3600 - minute * 60);
              if (day1 < 10) {
                day1 = "0" + day1;
              }
              if (hour < 10) {
                hour = "0" + hour;
              }
              if (minute < 10) {
                minute = "0" + minute;
              }
              if (second < 10) {
                second = "0" + second;
              }
              item.timeStr = day1 + '天' + hour + '时' +
                  minute + '分' + second + '秒'
            }
          })
          state.inquiryList = payload;
        }, 1000);
      } else {
        state.interval = setInterval(function () { //倒计时
          payload.forEach(item => {
            var now = new Date();
            var leftTime = 0
            if (item.orderStatus.value==2) {
              leftTime =  dayjs(item.quoteEndTime).valueOf() - now.getTime();
            }
            if (leftTime > 0) {
              let leftsecond = parseInt(leftTime / 1000);
              var day1 = Math.floor(leftsecond / (60 * 60 * 24));
              var hour = Math.floor((leftsecond - day1 * 24 * 60 * 60) / 3600);
              var minute = Math.floor((leftsecond - day1 * 24 * 60 * 60 - hour * 3600) / 60);
              var second = Math.floor(leftsecond - day1 * 24 * 60 * 60 - hour * 3600 - minute * 60);
              if (day1 < 10) {
                day1 = "0" + day1;
              }
              if (hour < 10) {
                hour = "0" + hour;
              }
              if (minute < 10) {
                minute = "0" + minute;
              }
              if (second < 10) {
                second = "0" + second;
              }
              item.timeStr = day1 + '天' + hour + '时' +
                  minute + '分' + second + '秒'
            }
          })
          state.inquiryList = payload;
        }, 1000);
      }*/

    },
  },
  actions: {
    async print({ state, commit }, params) {
      try {
        state.pageLoading = true;
        const payload = getLoginPayload("xyh800101", {ContractNo:params});
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          return  res
        }
      } finally {
        state.pageLoading = false
      }
    },
    async adminApplyPermission({ state, commit }, params) {
      try {
        state.pageLoading = true;
        const payload = getLoginPayload("use807705", params);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          return  res
        }
      } finally {
        state.pageLoading = false
      }
    },
    async supplierContract({ state, commit }, params) {
      try {
        state.pageLoading = true;
        const payload = getLoginPayload("use807707", params);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          return  res
        }
      } finally {
        state.pageLoading = false
      }
    },
    async modifyContract({ state, commit }, params) {
      try {
        state.pageLoading = true;
        const payload = getLoginPayload("xyh300305", params);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          return  res
        }
      } finally {
        state.pageLoading = false
      }
    },


    async adminApplyPermissionInit({ state, commit }, params) {
      try {
        state.pageLoading = true;
        const payload = getLoginPayload("xyh300304", params);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          return  res
        }
      } finally {
        state.pageLoading = false
      }
    },
    async getNewSupplierList({ state, commit }, params) {
      try {
        state.pageLoading = true;
        const payload = getPayload("xyh300301", {});
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          return  res
        }
      } finally {
        state.pageLoading = false
      }
    },
    async getResidentStatus({ state, commit }, params) {
      try {
        state.pageLoading = true;
        const payload = getLoginPayload("xyh300302", {EnterpID:params});
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          return  res
        }
      } finally {
        state.pageLoading = false
      }
    },
    async getSupplierStatus({ state, commit }, params) {
      try {
        state.pageLoading = true;
        const payload = getLoginPayload("hhm970393", params);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          return  res
        }
      } finally {
        state.pageLoading = false
      }
    },
    async getAuthority({ state, commit }, params) {
      try {
        state.pageLoading = true;
        let body = {
          EnterpID:params.company_id,                //类型：String  必有字段  备注：企业ID
          UserID:params.userid                //类型：String  必有字段  备注：用户ID
        }
        const payload = getLoginPayload("xyh300303", body);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          return  res
        }
      } finally {
        state.pageLoading = false
      }
    },
    async toBuySubmit({ state, commit }, params) {
      try {
        state.pageLoading = true;
        const payload = getLoginPayload("hhm969021",params);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          return  res
        }
      } finally {
        state.pageLoading = false
      }
    },
    async submit({ state, commit }, params) {
      try {
        state.pageLoading = true;
        const payload = getLoginPayload("hhm982021",params);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          return  res
        }
      } finally {
        state.pageLoading = false
      }
    },
    //竞价列表
    async getDataInfo({ state, commit }, params) {
      try {
        state.pageLoading = true;
        const payload = getPayload("hhm970112",{});
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          return  res.resultdata
        }
      } finally {
        setTimeout(function () { state.pageLoading = false; }, 1000);
      }
    },
    async getOfferDetailsInfo({ state, commit }, params) {
      try {
        state.pageLoading = true;
        let body ={
          activityId:params
        }
        const payload = getPayload("hhm982022",body);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          commit('setOfferDetailsInfo', res.resultdata)
          commit("setTimeStr2",  res.resultdata.head);
        }
      } finally {
        setTimeout(function () { state.pageLoading = false; }, 1000);
      }
    },
    async getInquiryDetailsInfo({ state, commit }, params) {
      try {
        state.pageLoading = true;
        let body ={
          orderNo:params
        }
        const payload = getPayload("hhm970392",body);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          console.log(res,"res")
          commit('setInquiryDetailsInfo', res.resultdata)
          commit("setTimeStr",  res.resultdata.head);
        }
      } finally {
        setTimeout(function () { state.pageLoading = false; }, 1000);
      }
    },

    //竞价列表
    async getInquiryList2({ state, commit }, params) {
      try {
        state.pageLoading = true;
        const payload = getPayload("hhm970017",params);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          commit("setInquiryPriceList", res.resultdata.data);
          commit("setPagination2", res.resultdata);
        }
      } finally {
        setTimeout(function () { state.pageLoading = false; }, 1000);
      }
    },
    //集采列表
    async getInquiryList({ state, commit }, params) {
      try {
        state.pageLoading = true;
        const payload = getPayload("hhm970391",params);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          commit("setInquiryList", res.resultdata.data);
          commit("setPagination", res.resultdata);
        }
      } finally {
        setTimeout(function () { state.pageLoading = false; }, 1000);
      }
    },
    //中标公示
    async getBidActivityWinner({ state, commit }, params) {
      try {
        state.pageLoading = true;
        let body = {};
        const payload = getPayload("hhm970016",body);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          commit("setBidActivityWinne", res.resultdata);
        }
      } finally {
        state.pageLoading = false;
      }
    },

  }
};
