import * as HttpServices from "@/utils/http";
import { getLoginPayload,getPayload } from "@/utils/server";
import dayjs from 'dayjs'
export default {
  namespaced: true,
  moduleName: "mall", // 模块名
  state: {
    // 额外的state
    pageLoading: false,
    keyword:"",
  },
  mutations: {
    setWord: (state, data) => {
      state.keyword = data;
    },
  },
  actions: {

    //现货资源初始化 （头部所有筛选条件）
    async mallSearchData({ state }, params = {}) {
      state.pageLoading = true;
      try {
        const payload = getPayload("hhm976114", params);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          state.pageLoading = false;
          return res
        }
      } finally {
        state.pageLoading = false;
      }
    },
    //现货资源列表
    async mallListData({ state }, params) {
      state.pageLoading = true;
      try {
        const payload = getPayload("hhm976013", params);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          state.pageLoading = false;
          return res
        }
      } finally {
        state.pageLoading = false;
      }
    },

  }
};
