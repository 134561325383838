/*
 *===============DdmgCloudPlatform3 V2.0===============
 * DdmgCloudPlatform3 V2.0
 * Copyright@大大买钢2018,All Rights Reserved
 * Author: XieZhuoxun(59296309@qq.com)
 * Date: 2018/7/24  15:21
 * TODO: storage相关操作：存取 处理本地数据缓存的工具
 *===============DdmgCloudPlatform3 V2.0===============
 */

import { appInfo } from "@/utils/appConfig";

const appKey = appInfo._appname + '_';

const storage = {
  setStorage: function (key, value, duration) {
    const data = {
      value: value,
      expiryTime: !duration || isNaN(duration) ? 0 : this.getCurrentTimeStamp() + parseInt(duration),
    };
    localStorage[appKey + key] = JSON.stringify(data);
  },
  getStorage: function (key) {
    const data = localStorage[appKey + key];
    if (!data || data === "null") {
      return null;
    }
    const now = this.getCurrentTimeStamp();
    let obj;
    try {
      obj = JSON.parse(data);
    } catch (e) {
      return null;
    }
    if (obj.expiryTime === 0 || obj.expiryTime > now) {
      return obj.value;
    }
    return null;
  },
  removeStorage: function (key) {
    localStorage.removeItem(appKey + key);
  },
  getSession: function (key) {
    const data = sessionStorage[appKey + key];
    if (!data || data === "null") {
      return null;
    }
    return JSON.parse(data).value;
  },
  setSession: function (key, value) {
    const data = {
      value: value,
    };
    sessionStorage[appKey + key] = JSON.stringify(data);
  },
  getCurrentTimeStamp: function () {
    return Date.parse(new Date());
  },
};
export default storage;
