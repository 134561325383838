import Vue from "vue";
import Vuex from "vuex";
import d2admin from "./d2admin";
import ddmg from "./ddmg";
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    ddmg,
    d2admin
  },

});
