import * as HttpServices from "@/utils/http"
import { getLoginPayload, getPayload } from "@/utils/server"
export default {
  namespaced: true,
  state: {
    gwcOrderList: [], // 购物车数据
    goodsInfo: {},
    adjustmentFactor: 0,
    pageLoading: false,
    goodsCartList: [],
  },
  actions: {
    //修改
    async getEditGWC({ state, commit, dispatch }, params) {
      console.log(params)
      try {
        state.pageLoading = true
        const payload = getLoginPayload("hhm977031", params)
        const res = await HttpServices.startApiReq(payload)
        if (res.msgcode === 1) {
          // dispatch('getGoodsCart')
          return res
        }
      } finally {
        state.pageLoading = false
      }
    },
    async deleteGwc({ state, commit, dispatch }, params) {
      console.log(params)
      try {
        state.pageLoading = true
        let body = {
          id: params.id,
        }
        const payload = getLoginPayload("hhm977041", body)
        const res = await HttpServices.startApiReq(payload)
        if (res.msgcode === 1) {
          dispatch("getGoodsCart")
          return res
        }
      } finally {
        state.pageLoading = false
      }
    },
    async getAddGWC({ state, commit, dispatch }, params) {
      try {
        state.pageLoading = true
        let body = {
          goodsId: params.id,
          quantity: 1, //类型：Number  必有字段  备注：无
          weight: 1,
        }
        const payload = getLoginPayload("hhm977021", body)
        const res = await HttpServices.startApiReq(payload)
        if (res.msgcode === 1) {
          dispatch("getGoodsCart")
          return res
        }
      } finally {
        state.pageLoading = false
      }
    },
    //添加购物车2
    async getStockGWC({ state, commit, dispatch }, params) {
      try {
        state.pageLoading = true
        const payload = getLoginPayload("hhm977021", params)
        const res = await HttpServices.startApiReq(payload)
        if (res.msgcode === 1) {
          dispatch("getGoodsCart")
          return res
        }
      } finally {
        state.pageLoading = false
      }
    },
    //购物车列表
    async getGoodsCart({ state, commit }, params) {
      try {
        state.pageLoading = true
        let body = { companyId: params }
        const payload = getLoginPayload("hhm977012", body)
        const res = await HttpServices.startApiReq(payload)
        if (res.msgcode === 1) {
          commit("setGoodsCartList", res.resultdata)
        }
      } finally {
        state.pageLoading = false
      }
    },
    // //物资详情
    async getGoods({ state, commit }, params) {
      try {
        state.pageLoading = true
        let body = { id: params }
        const payload = getPayload("hhm976091", body)
        const res = await HttpServices.startApiReq(payload)
        if (res.msgcode === 1) {
          commit("setGoodsInfo", res.resultdata)
        }
        return res
      } finally {
        state.pageLoading = false
      }
    },
  },
  mutations: {
    setGoodsCartList(state, payload) {
      if (payload.list != null && payload.list) {
        payload.list.map((xitem) => {
          xitem.check = false
          xitem.list.forEach((item) => {
            if (item.price != "" && item.price != "0.00") {
              item.newPrice = item.price * 1 * (item.weight * 1)
            } else {
              item.newPrice = 0
            }
            item.check = false
          })
        })
      }
      state.goodsCartList = payload.list
    },
    setGoodsInfo(state, payload) {
      state.goodsInfo = payload
    },
  },
}
