import * as HttpServices from "@/utils/http";
import { getLoginPayload,getPayload } from "@/utils/server";
import dayjs from 'dayjs'
export default {
  namespaced: true,
  moduleName: "shop", // 模块名
  //tableColumns,
  //  表头列表
  state: {
    // 额外的state
    pageLoading2: false,
    pageLoading: false,
    companyList: [],
    regionProductList: {},
    pagination: {},
    companyDeatil: {}
  },
  mutations: {
    setCompany: (state, data) => {
      state.companyList = data.data;
      state.pagination = data;
    },
    setRegion: (state, data) => {
      let regionListV2 =data.areaList.map(obj=>{
        return { isActive:false, name:obj }
      })
      let productNameList =data.mainBusinessCategoryList.map(obj=>{
        return { isActive:false, name:obj }
      })
      regionListV2.unshift( { isActive:true, name:'全部'  })
      productNameList.unshift( { isActive:true, name:'全部' })
      state.regionProductList = {
        regionListV2: regionListV2,
        productNameList: productNameList
      }
    },
    setCompanyDeatil: (state, data) => {
      state.companyDeatil = data;
    },
  },
  actions: {
    async getCompanyDeatil({ state, commit }, params) {
      try {
        state.pageLoading = true;
        let body = {id:params}
        const payload = getPayload("hhm997091", body);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          commit("setCompanyDeatil", res.resultdata);
          return res
        }
      } finally {
        state.pageLoading = false;
      }

    },
    //商铺推荐-筛选初始化
    async getRegionAndProduct({ state, commit }, params) {
      try {
        state.pageLoading = true;
        let body = { };
        const payload = getPayload("hhm997112",body);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          commit("setRegion", res.resultdata);
          return res
        }
      } finally {
        state.pageLoading = false;
      }
    },
    //商铺推荐
    async getCompany({ state, commit }, params) {
      try {
        state.pageLoading2 = true;
        const payload = getPayload("hhm997012",params);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          console.log(res,'123');
          commit("setCompany", res.resultdata);
          // commit("setCompany", res.resultdata.data);
        }
      } finally {
        state.pageLoading2 = false;
      }
    },
    //列表
    async shopDeatilListData({ state }, params = {}) {
      try {
        state.pageLoading2 = true;
        const payload = getPayload("hhm976011",params);
        const res = await HttpServices.startApiReq(payload);
        if (res.msgcode === 1) {
          return res;
          // commit("setCompany", res.resultdata);
          // commit("setCompany", res.resultdata.data);
        }
      } finally {
        state.pageLoading2 = false;
      }
    },
  }
};
