import Vue from "vue";
import VueRouter from "vue-router";
//import home from '@/views/home/index.vue'
import errorf from "@/views/error/404/index.vue";
import storage from "@/utils/storage";
import Fingerprint2 from "fingerprintjs2";
//import { Message, MessageBox } from "element-ui";
// 进度条
// import NProgress from "nprogress";
// import "nprogress/nprogress.css";

//import store from "@/store/index";
//import util from "@/libs/util.js";
//import { HashBaseUrl } from "@/router/routes";

// 路由数据
//import routes from "./routes";

// 供应链服务
const chain = [
  {
    path: "/supplyChain/index",
    name: "supplyChain",
    component: () => import("@/views/supplyChain/index.vue"),
  },
  {
    path: "/supplyChain/details/:id",
    name: "supplyChain-details",
    component: () => import("@/views/supplyChain/details.vue"),
  },
];
// 关于我们
const aboutUs = [
  {
    path: "/aboutUs/index",
    name: "aboutUs",
    component: () => import("@/views/aboutUs/index.vue"),
  },
];

const routes = [
  {
    path: "/",
    redirect: "index",
  },
  // {
  //     path: '/*/',//首页
  //     name: 'home',
  //     component: () => import('@/views/home/index.vue')
  // },
  {
    path: "/index", //首页
    name: "home",
    component: () => import("@/views/home/index.vue"),
  },
  {
    path: "/mall", //现货资源
    name: "mall",
    component: () => import("@/views/mall/index.vue"),
  },
  {
    path: "/bid", //工程专场-竞价
    name: "bid",
    component: () => import("@/views/bid/index.vue"),
  },
  {
    path: "/supplier", //供应商入驻
    name: "supplier",
    component: () => import("@/views/bid/supplier.vue"),
  },
  {
    path: "/supplierApply", //供应商入驻2
    name: "supplierApply",
    component: () => import("@/views/bid/supplierApply.vue"),
  },
  {
    path: "/inquiry", //工程专场-询价
    name: "inquiry",
    component: () => import("@/views/bid/inquiry.vue"),
  },
  {
    path: "/offerDetails", //竞价专场详情
    name: "offerDetails",
    component: () => import("@/views/bid/offerDetails.vue"),
  },
  {
    path: "/inquiryDetails", //询价专场详情
    name: "inquiryDetails",
    component: () => import("@/views/bid/inquiryDetails.vue"),
  },
  {
    path: "/shop", //店铺页面
    name: "shop",
    component: () => import("@/views/shopdetail/index.vue"),
  },
  {
    path: "/info", //价格走势详情
    name: "info",
    component: () => import("../components/info.vue"),
  },
  {
    path: "/cart", //购物车-订单明细 也就是立即购买的页面
    name: "cart",
    component: () => import("../components/cart.vue"),
  },
  {
    path: "/product", //产品购买详情
    name: "product",
    component: () => import("../components/product.vue"),
  },
  {
    path: "/shoplist", //店铺列表
    name: "shoplist",
    component: () => import("@/views/shoplist/index.vue"),
  },
  {
    path: "/toBuy", //我要求购
    name: "toBuy",
    component: () => import("@/views/toBuy/index.vue"),
  },
  {
    path: "/eoc", //我要求购
    name: "eoc",
    component: () => import("@/views/error/eoc/index.vue"),
  },
  {
    path: "*",
    name: "error",
    component: errorf,
  },
  ...chain,
  ...aboutUs,
];

// fix vue-router NavigationDuplicated
Vue.use(VueRouter);

// 导出路由 在 main.js 里使用
const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path == "/admin_eKuLVDm8ULDtqnQxfQ") {
    window.location.href = "https://mall.ddmg.com/admin_eKuLVDm8ULDtqnQxfQ";
  }
  if (!storage.getStorage("fingerprint")) {
    Fingerprint2.get((components) => {
      // 参数只有回调函数时，默认浏览器指纹依据所有配置信息进行生成
      const values = components.map((component) => component.value); // 配置的值的数组
      const murmur = Fingerprint2.x64hash128(values.join(""), 31); // 生成浏览器指纹
      storage.setStorage("fingerprint", murmur);
    });
    if (to.path == "//") {
      setTimeout(next({ name: "home" }), 300);
    } else {
      setTimeout(next(), 300);
    }
  } else {
    if (to.path == "//") {
      next({ name: "home" });
    } else {
      next();
    }
  }
});
export default router;
